import styled from 'styled-components'
import { ArrowBackIcon } from 'widgets'
import Table from '.'

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;

  ${({ theme }) => theme.mediaQueries.lg} {
    overflow-x: auto;
  }

  /* .ant-table-wrapper {
    min-width: 700px;
  } */

  .ant-pagination-item {
    border: none;

    a {
      padding: 0;
      font-weight: 700;

      ${({ theme }) => theme.mediaQueries.xl} {
        font-size: 20px;
      }
    }

    &:hover {
      a {
        color: #00a0ff;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    align-items: center;
    justify-content: center;

    &.ant-pagination-disabled {
      > div {
        background-color: transparent;

        svg path {
          fill: #6f767e;
        }
      }
    }
  }
`

const TableHomePage = ({ ...props }: any) => {
  return (
    <Wrapper>
      <Table
        scroll={{ x: true }}
        pagination={{
          rowKey: (item) => item,
          showTotal: (total) => <p className="text-[16px] mr-4 xl:mr-10">Total: {total}</p>,
          showSizeChanger: false,
          itemRender: (_, type, originalElement) => {
            if (type === 'prev') {
              return (
                <div className="flex items-center justify-center w-[36px] h-[36px] p-1 rounded-[50%] bg-[#1A1D1F]">
                  <ArrowBackIcon width={20} />
                </div>
              )
            }
            if (type === 'next') {
              return (
                <div className="flex items-center justify-center w-[36px] h-[36px] p-1 rounded-[50%] bg-[#1A1D1F]">
                  <ArrowBackIcon style={{ transform: 'rotate(180deg)' }} width={20} />
                </div>
              )
            }
            return originalElement
          },
        }}
        {...props}
      />
    </Wrapper>
  )
}

export default TableHomePage
