import { useWeb3React } from '@web3-react/core'
import { SLOW_INTERVAL } from 'config/constants'
import { useTranslation } from 'contexts/Localization'
import { useGetBuyTicketSwr } from 'hooks/swr/useGetBuyTicketSwr'
import { useGetMyTicketSwr } from 'hooks/swr/useGetMyTicketSwr'
import { useState } from 'react'
import styled from 'styled-components'
import { Box } from 'widgets'
import WinnerTable from './WinnerTable'


const TabTable = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const [view, setView] = useState(1)

  const { data: winnerHistory } = useGetBuyTicketSwr({ refreshInterval: SLOW_INTERVAL })
  const { data: myHistory } = useGetMyTicketSwr(account)

  return (
    <Wrapper>
      <TabButton>
        <Box className={`button-1 ${view === 1 ? 'active' : ''}`} onClick={() => setView(1)}>
          <p className="lg:text-[20px] text-base text-center font-bold" style={{ color: view === 1 ? '#000' : '#fff' }}>
            {t("Winners Board")}
          </p>
        </Box>

        <Box className={`button-2 ${view === 2 ? 'active' : ''}`} onClick={() => setView(2)}>
          <p className="lg:text-[20px] text-base text-center font-bold" style={{ color: view === 2 ? '#000' : '#fff' }}>
            {t("History")}
          </p>
        </Box>
      </TabButton>

      <TabContent>
        <WinnerTable data={view === 1 ? winnerHistory : (myHistory || [])} isMyHistory={view === 2} t={t}/>
      </TabContent>
    </Wrapper>
  )
}

export default TabTable

const Wrapper = styled.div`
  width: 100%;
  max-width: 1232px;
  padding: 0 16px;
  margin: 62px auto 76px;
`

const TabButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  width: fit-content;
  margin: 0 auto 50px;
  border-radius: 55px;
  border: 1px solid #ffb905;
  background: #0f1540;
  overflow: hidden;

  .button-1,
  .button-2 {
    padding: 16px 24px;
    position: relative;
    text-align: center;
    cursor: pointer;

    &.active {
      border-radius: 55px;
      border: 1px solid #ffb905;
      background: linear-gradient(32deg, #ffc773 -7.07%, #f90 84.5%);
    }
  }
`

const TabContent = styled.div`
  position: relative;
  z-index: 2;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0);
  background: linear-gradient(114deg, #0d021c -43.49%, #0f1841 90.99%);
  box-shadow: 0px 0px 140px 0px rgba(34, 7, 195, 0.44);
  backdrop-filter: blur(1.4px);
`
