import { gql } from 'graphql-request'
import useSWR, { SWRConfiguration } from 'swr'
import { foggyClient } from 'utils/graphql'
import SWR_KEY from './constant'

const TICKET_LIST = gql`
  query MyQuery($first: Int, $skip: Int) {
    buyTickets(
      where: { rare_in: ["2", "3"], blockTimestamp_gte: "1731225600"  }
      orderBy: "blockTimestamp"
      orderDirection: "desc"
      first: $first
      skip: $skip
    ) {
      id
      buyer
      price
      rare
      rewardAmount
      tokenId
      transactionHash
      blockTimestamp
    }
  }
`

const getBuyTicketList = async (): Promise<{ data?: any; error: boolean }> => {
  try {
    const first = 1000
    let skip = 0
    let data: any | undefined

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const dataRs = await foggyClient.request(TICKET_LIST, { first: Number(first), skip: Number(skip) })

      if (!data) {
        data = {
          buyTickets: [],
        }
      }

      if (!dataRs || !dataRs?.buyTickets || !dataRs?.buyTickets?.length) {
        break
      }

      data.buyTickets.push(...dataRs.buyTickets)

      skip += 1000
    }

    return {
      data: data?.buyTickets,
      error: false,
    }
  } catch (error) {
    console.error('getBuyTicketList', error)
    return { error: true }
  }
}

export const useGetBuyTicketSwr = (options?: SWRConfiguration) => {
  const fetchData = async () => getBuyTicketList().then((res) => res?.data)

  return useSWR(SWR_KEY.GET_WINNER_TICKET, fetchData, {
    revalidateOnFocus: false,
    ...options,
  })
}
