import TableHomePage from 'components/Table/TableHomePage'
import moment from 'moment-timezone'
import { formatAmountOrigin } from 'utils/formatInfoNumbers'

export const STATUS_CONFIG = {
  1: {
    css: 'bg-[#FE540929] text-[#FE5409]',
    label: 'Missed',
  },
  2: {
    css: 'bg-[#05FD1E29] text-[#05FD1E]',
    label: 'Prize',
  },
  3: {
    css: 'bg-[#0063F729] text-[#3184FF]',
    label: 'Jackpot',
  },
}

const HistoryTable = ({ data }) => {
  return (
    <TableHomePage
      columns={[
        {
          title: 'Time',
          key: 'blockTimestamp',
          dataIndex: 'blockTimestamp',
          render: (value) => (
            <p className="md:text-[16px] text-[14px]">{moment(+value * 1000).format('DD MMM YYYY HH:mm A')}</p>
          ),
        },
        {
          title: 'NFT ID',
          key: 'tokenId',
          dataIndex: 'tokenId',
          render: (value) => <p className="md:text-[16px] text-[14px]">#{value}</p>,
        },
        {
          title: 'Rarity NFT',
          key: 'rare',
          dataIndex: 'rare',
          align: 'center',
          render: (value) => (
            <div className={`px-2 py-[6px]  rounded-[20px] text-center font-[500] ${STATUS_CONFIG[+value]?.css}`}>
              {STATUS_CONFIG[+value]?.label}
            </div>
          ),
        },
        {
          title: 'Reward',
          key: 'rewardAmount',
          dataIndex: 'rewardAmount',
          align: 'right',
          render: (value, item) => (
            <div className="flex items-center space-x-2 justify-end">
              <p className="lg:text-[20px] font-bold">
                {item?.rare !== '1' ? formatAmountOrigin(+value / 1e9) : '- -'}
              </p>
              {item?.rare !== '1' && <img src="/logo.png" alt="" className="w-[32px] aspect-square" />}
            </div>
          ),
        },
      ]}
      dataSource={data}
      scroll={{ x: 800 }}
      rowKey='id'
    />
  )
}

export default HistoryTable
