import { Table as AntTable } from 'antd'
import styled from 'styled-components'
import { Box } from '../../../packages/uikit/src/components/Box'

const TableWrapper = styled(Box)`
  .ant-table {
    background: transparent;
    color: #ffffff;
    font-size: 16px;
  }
  .ant-table-thead {
    background: #0b0b26;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    > tr > th {
      background: transparent;
      color: #d4d4d8;
      font-size: 14px;
      font-weight: 700;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 16px;
      }

      ${({ theme }) => theme.mediaQueries.xl} {
        font-size: 20px;
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-thead > tr > th {
    border: none;

    ::before {
      display: none;
    }
  }
`

function Table({ columns, dataSource, pagination, loading, ...props }: any) {
  return (
    <TableWrapper>
      <AntTable dataSource={dataSource} columns={columns} pagination={pagination} loading={loading} {...props}/>
    </TableWrapper>
  )
}

export default Table
