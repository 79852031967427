import TableHomePage from 'components/Table/TableHomePage'
import { formatCode } from 'helpers/CommonHelper'
import moment from 'moment-timezone'
import { getBscScanLink } from 'utils'
import { formatAmountOrigin } from 'utils/formatInfoNumbers'
import { Box } from 'widgets'
import { STATUS_CONFIG } from './HistoryTable'

const WinnerTable = ({ data, isMyHistory = false, t }) => {
  return (
    <TableHomePage
      columns={[
        {
          title: t('Time'),
          key: 'blockTimestamp',
          dataIndex: 'blockTimestamp',
          render: (value) => (
            <p className="md:text-[16px] text-[14px]">{moment.utc(+value * 1000).format('DD MMM YYYY HH:mm A')}</p>
          ),
        },
        isMyHistory
          ? {}
          : {
              title: t('Address'),
              key: 'buyer',
              dataIndex: 'buyer',
              render: (value) => (
                <Box
                  as="p"
                  className="md:text-[16px] text-[14px] cursor-pointer"
                  onClick={() => window?.open(getBscScanLink(value, 'address', 56))}
                >
                  {formatCode(value, 6, 6)}
                </Box>
              ),
            },
        {
          title: 'NFT ID',
          key: 'tokenId',
          dataIndex: 'tokenId',
          render: (value) => <p className="md:text-[16px] text-[14px]">#{value}</p>,
        },
        {
          title: t('Rarity NFT'),
          key: 'rare',
          dataIndex: 'rare',
          align: 'center',
          render: (value) => (
            <div className={`px-2 py-[6px] rounded-[24px] text-center font-[500] ${STATUS_CONFIG[+value]?.css}`}>
              {STATUS_CONFIG[+value]?.label}
            </div>
          ),
        },
        {
          title: t('Reward'),
          key: 'rewardAmount',
          dataIndex: 'rewardAmount',
          align: 'right',
          render: (value) => (
            <div className="flex items-center space-x-2 justify-end">
              <p className="lg:text-[20px] font-bold">{formatAmountOrigin(+value / 1e9)}</p>
              <img src="/logo.png" alt="" className="w-[32px] aspect-square" />
            </div>
          ),
        },
      ]}
      dataSource={data}
      scroll={{ x: 800 }}
      rowKey="id"
    />
  )
}

export default WinnerTable
