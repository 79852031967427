import { gql } from 'graphql-request'
import useSWR, { SWRConfiguration } from 'swr'
import { foggyClient } from 'utils/graphql'
import SWR_KEY from './constant'

const TICKET_LIST = gql`
  query MyQuery($account: String, $first: Int, $skip: Int) {
    buyTickets(
      where: { buyer: $account }
      orderBy: "blockTimestamp"
      orderDirection: "desc"
      first: 1000
    ) {
      id
      buyer
      price
      rare
      rewardAmount
      tokenId
      transactionHash
      blockTimestamp
    }
  }
`

const getBuyTicketList = async (account: string): Promise<{ data?: any; error: boolean }> => {
  try {
    const data = await foggyClient.request(TICKET_LIST, { account })

    return {
      data: data?.buyTickets,
      error: false,
    }
  } catch (error) {
    console.error('getBuyTicketList', error)
    return { error: true }
  }
}

export const useGetMyTicketSwr = (account: string | undefined | null ,options?: SWRConfiguration) => {
  const fetchData = async () => getBuyTicketList(account).then((res) => res?.data)

  return useSWR(account ? [SWR_KEY.GET_MY_TICKET, account] : null, fetchData, {
    revalidateOnFocus: false,
    ...options,
  })
}
